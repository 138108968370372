import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Restaurant from './pages/Restaurant';
import ProductModel from './pages/ProductModel';
import LandingPage from './pages/Landing';
import LoadingPage from './pages/Loading';
import './App.css';

function App() {
  // Local storage for language is set to 'az' by default
  if (!localStorage.getItem('lang')) {
    localStorage.setItem('lang', 'en');
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<LandingPage />}
        />
        <Route
          path="/:restaurantId"
          element={<RestaurantWithId />}
        />
        <Route path="/model" element={<ProductModel />} />
      </Routes>
    </Router>
  );
}

function RestaurantWithId() {
  const { restaurantId } = useParams(); // Get restaurantId from the URL
  const [restaurantData, setRestaurantData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Function to fetch data from Firebase Firestore
  const fetchDataFromFirestore = async () => {

    let local_data = JSON.parse(localStorage.getItem("restaurantData"))
    // console.log("local data:", local_data)
    // console.log(local_data.time - (new Date()).getTime())
    if (local_data && local_data.id != restaurantId && ((new Date()).getTime() - local_data.time) < 3600000) {
      // if(false){
      //Add something to check for last updated time and refresh data if local is too old
      console.log("Using Cached data")
      setRestaurantData(local_data)
      setIsLoading(false)
    }
    else {
      try {
        console.log("Getting db data")
        const db = firebase.firestore();
        const restaurantCollection = db.collection('businesses');
        const querySnapshot = await restaurantCollection.doc(restaurantId).get();
        const data = querySnapshot.data();
        setRestaurantData(data);
        console.log(data)
        localStorage.setItem("restaurantData", JSON.stringify({ ...data, time: (new Date()).getTime() }))
        setIsLoading(false); // Set isLoading to false once data is fetched
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
      }
    }

  };

  useEffect(() => {
    fetchDataFromFirestore();
  }, [restaurantId]); // Fetch data whenever restaurantId changes


  // return <LoadingPage />;

  if (isLoading) {
    return <LoadingPage />;
  } else {
    return <Restaurant data={restaurantData} />;
  }
}

function LoadingComponent() {
  return <div>Loading...</div>;
}

export default App;
