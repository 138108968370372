import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const config = {
  apiKey: "AIzaSyBQJdCdw1zr-GnEdVKZpGT5k3MW382jZ4E",
  authDomain: "gormeli.firebaseapp.com",
  databaseURL: "https://gormeli-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "gormeli",
  storageBucket: "gormeli.appspot.com",
  messagingSenderId: "213383658864",
  appId: "1:213383658864:web:5e93f8785a9a381235e3b5",
  measurementId: "G-XSWB7FXRLJ"
};
firebase.initializeApp(config);

const firestore = firebase.firestore();
const settings = { /* your settings... */ timestampsInSnapshots: true };
firestore.settings(settings);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
