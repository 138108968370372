import React, { useState, useEffect, useParams, useRef, Image } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import style from "./style.module.css";



const LandingPage = () => {

    return (
        <>
            <div className={style.wrapperLanding} style={{ height: window.innerHeight }}>
                <h3>A 3D Model is worth a thousand words...</h3>
                <img src={require("../../logos/culinaar.png")} class="img img-responsive" />
                <a href="https://docs.google.com/forms/d/1jCjwCGUOoidomh1CuR1TZ0Er0KbVgyisfoFd_a0YQpQ" target="_blank" id={style.contactUs}>
                    Contact Us
                </a>
            </div>
        </>
    );

}


export default LandingPage;