import React from "react";
import "./style.modules.css";

const Product = (props) => {
    let { cover, title, desc, price } = props;

    return (
        cover !== undefined && cover !== "" ? (
            <div className="product" style={{ backgroundImage: `url(${cover})` }}>
                <div className="product-info-part">
                    <h2 className="product-name">{title}</h2>
                    <div className="description">
                        {desc}
                    </div>
                    <p className="price">{price}</p>
                </div>
            </div>
        ) : (
            <div className="product product-with-no-image">
                <div className="product-info-part">
                    <h2 className="product-name">{title}</h2>
                    <div className="description">
                        {desc}
                    </div>
                    <p className="price">{price}</p>
                </div>
            </div>
        )
    );
};

export default Product;
