import React, { useState, useEffect, useParams, useRef } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link, useLocation } from "react-router-dom";


import Navbar from '../../components/navbar';
import Product from '../../components/product';
import CategoryBar from '../../components/categoryBar/CategoryBar.ts';
import style from "./style.module.css";



const Restaurant = (props) => {

    let lang = localStorage.getItem('lang');
    const { state } = useLocation()
    const categoryRef = useRef()
    let restaurantData = props.data;
    let products = restaurantData.menu_items;

    const [selectedCategory, setSelectedCategory] = useState(state ? state.selectedCategory : restaurantData.menu_categories[0]["id"]);
    const [filteredProducts, setFilteredProducts] = useState(products.filter(product => product.type.includes(selectedCategory)))
    const [scrollPosition, setScrollPosition] = useState(state ? state.scrollPosition : 0);
    const [categoryScroll, setCategoryScroll] = useState(state ? state.categoryScroll : 0)
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    const handleCategoryScroll = (e) => {
        // console.log(e.target.scrollLeft)
        const position = e.target.scrollLeft;
        setCategoryScroll(position);
    }

    const scrollCategory = () => {
        categoryRef.current.scrollLeft = categoryScroll
    }

    // Filter products based on selected category
    useEffect(() => {
        setFilteredProducts(products.filter(product => product.type.includes(selectedCategory)))
        window.scrollTo({
            top: scrollPosition,
            behavior: "instant"
        })
        scrollCategory()

        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [selectedCategory])

    // setFilteredProducts(selectedCategory
    //     ? products.filter(product => product.type.includes(selectedCategory))
    //     : products)
    return (
        <>
            <Navbar data={restaurantData} />

            <div className='container'>

                <Carousel className={style.topSlider} showArrows={false} showThumbs={false} infiniteLoop autoPlay showStatus={false} interval={6000}>
                    {restaurantData.slider_photos.map((photo, index) => (
                        <div key={index}>
                            <img src={photo} />
                        </div>))
                    }
                </Carousel>

                <div className={style.categories} ref={categoryRef} onScroll={handleCategoryScroll} onLoad={scrollCategory}>

                    {restaurantData.menu_categories.sort((a, b) => {
                        const aIndex = restaurantData.category_order.indexOf(a.id);
                        const bIndex = restaurantData.category_order.indexOf(b.id);

                        return aIndex - bIndex;
                    }).map((category, index) => {
                        let buttonStyle = style.button
                        if (category.id == selectedCategory) {
                            buttonStyle = style.selectedButton
                        }
                        return <button key={index} className={buttonStyle} onClick={() => setSelectedCategory(category.id)}>{category[lang]}</button>
                    })}
                </div>

                <div className={style.products}>

                    {filteredProducts.map((product, index) => (

                        ((product.cover !== "" && product.cover !== undefined)) ? (
                            <Link key={index}
                                to={'/model'}
                                state={{ id: restaurantData.id, product: product, selectedCategory: selectedCategory, scrollPosition: scrollPosition, categoryScroll: categoryScroll }}
                            >
                                <Product title={product.title[lang]} zoom={product.zoom} desc={product.desc[lang]} price={product.price} cover={product.cover} />
                            </Link>
                        ) : (
                            <Product title={product.title[lang]} zoom={product.zoom} desc={product.desc[lang]} price={product.price} cover={product.cover} />
                        )
                    ))}

                </div>

            </div >

        </>
    )
}


export default Restaurant;

