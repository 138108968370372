import React, { Suspense, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import styles from './style.module.css';

import 'aframe';
import axios from "axios";
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF, Box, Html } from "@react-three/drei";
import { Entity, Scene } from 'aframe-react';


function Model(props) {
    const modelSrc = props.modelSrc;
    const { scene } = useGLTF(modelSrc);
    return <primitive object={scene} />;
}

const ProductModel = (props) => {

    let navigate = useNavigate();
    let lang = localStorage.getItem('lang');
    const { state } = useLocation();
    // console.log(state)
    const pageTranslation = {
        "about": {
            az: "Haqqında",
            en: "About",
            ru: "Описание",
        }
    }

    useEffect(() => {
        const canvas = document.querySelector('canvas');
        const parent = canvas.parentElement.parentElement;
        parent.style.position = "absolute";
    }, []);

    function Loader() {
        return <Html center>
            <i class="fa-solid fa-spinner fa-spin" style={{ fontSize: "16vw" }}></i>
        </Html>;
    }

    return (
        <>
                <div className={styles.backBtn} onClick={()=>navigate(`/${state.id}`, {state:{ 
                                                                                                        selectedCategory:state.selectedCategory, 
                                                                                                        scrollPosition:state.scrollPosition,
                                                                                                        categoryScroll:state.categoryScroll
                                                                                                    }
                                                                                                })}>
                    <i className="fa-solid fa-chevron-left"></i>
                </div>


            <Canvas camera={{ position: [-10, 15, 15], fov: 50, zoom: 20 + state.product.zoom }}>
                <ambientLight intensity={4} />
                <Suspense fallback={<Loader />}>
                    <Model position={[0, 0, 0]} scale={[10, 10, 10]} modelSrc={state.product.model} />
                </Suspense>
                <OrbitControls
                    panSpeed={0.2}
                    maxPolarAngle={(50 * Math.PI) / 180}
                    maxZoom={0.00001}
                    minDistance={5}
                    maxDistance={15}
                />
            </Canvas>

            <Carousel className={styles.carouselProductInfo} showIndicators={false} showArrows={false} showThumbs={false} infiniteLoop autoPlay showStatus={false} interval={7000}>
                <div id={styles.infoBar}>
                    <img src={state.product.cover} className='img img-responsive' />
                    <div id={styles.nameAndPrice}>
                        <i>{state.product.title[lang]}</i>
                        <p>{state.product.price}</p>
                    </div>
                </div>

                <div id={styles.ingredients}>
                    <h3>
                        {pageTranslation["about"][lang]}
                    </h3>
                    <i>
                        {state.product.desc[lang]}
                    </i>
                </div>
            </Carousel>
        </>
    );
}


export default ProductModel;